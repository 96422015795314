require('dotenv').config()

let host = ""
let publicFiles = ""
const local = 1
if (process.env.NODE_ENV === "development") {
    if (local === 1) {
        host = "http://localhost:3014/api"
        publicFiles = "http://localhost:3014/static"
    } else {
        host = "https://api-test.nekoadmin.com.ar/hpc/api"
        publicFiles = "https://api-test.nekoadmin.com.ar/hpc/static"
    }
} else {
    host = "https://api-prod.nekoadmin.com.ar/hpc/api"
    publicFiles = "https://api-prod.nekoadmin.com.ar/hpc/static"
}

const prodImages = publicFiles + "/images/products/"

const publicFolder = {
    prodImages
}

const auth = host + "/auth"
const routes = host + "/routes"
const permissions = host + "/permissions"
const franchises = host + "/franchises"

const authDir = {
    auth,
    sub: {
        franchise: auth + "/franchise"
    }
}

const permissionsDir = {
    permissions,
    sub: {
        list: "/list"
    }
}

const franchisesDir = {
    franchises,
    sub: {
        details: franchises + "/details",
        pass: franchises + "/pass"
    }
}

const routesDir = {
    routes,
    sub: {
        dashboard: routes + "/dashboard",
        changePass: routes + "/changePass",
        clientes: routes + "/clientes",
        productos: routes + "/productos",
        proveedores: routes + "/proveedores",
        ptosVta: routes + "/ptosVta",
        revendedores: routes + "/revendedores",
        stock: routes + "/stock",
        transportistas: routes + "/transportistas",
        userAdmin: routes + "/userAdmin",
        franquicias: routes + "/franquicias",
        reparaciones: routes + "/reparaciones",
        repuestos: routes + "/repuestos"
    }
}

const UrlNodeServer = {
    publicFolder,
    authDir,
    routesDir,
    permissionsDir,
    franchisesDir
}

export default UrlNodeServer