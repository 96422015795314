import Index from "views/admin/dashboard";
import Repairs from 'views/admin/repairs';

var routes = [
  {
    path: "/index",
    name: "Panel de Control",
    icon: "ni ni-tv-2 text-blue",
    component: Index,
    layout: process.env.PUBLIC_URL + "/admin",
    id: 0
  },
  {
    path: "/reparaciones",
    name: "Reparaciones",
    icon: "ni ni-tv-2 text-red",
    component: Repairs,
    layout: process.env.PUBLIC_URL + "/admin",
    id: 0
  }
];
export default routes;
