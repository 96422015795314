import React from 'react';
import { Card, CardBody } from 'reactstrap';

const RepairsList = () => {

    return (
        <Card style={{ marginTop: "30px" }}>
            <CardBody></CardBody></Card>
    )
}

export default RepairsList